.first__section {
  display: flex;
  background: $background;
  align-items: flex-start; }

.first__section_banner {
  max-width: 1120px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto; } }

.form__block_first {
  margin: 0 auto;
  margin-top: 130px;
  max-width: 480px;
  padding: 42px 95px; }

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px; }

.form__input {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border: 1px solid $form-grey;
  border-radius: 5px;
  outline: none;
  padding: 17px 12px;
  line-height: 48px;
  color: $text-grey;
  transition: .3s;
  font-size: 14px;
  &:focus {
    border-color: $yellow; }
  &:last-child {
    margin-bottom: 0; }
  &:hover {
    border-color: $yellow; } }

.form__button {
  margin-top: 10px;
  color: white;
  font-size: 14px;
  width: 100%;
  padding: 16px 0;
  background: $yellow;
  &:hover {
    color: white; }
  &:disabled {
    background: $white;
    color: #C4C4C4;
    border: 1px solid #C4C4C4; } }

.first__desc {
  text-align: center;
  margin-top: 35px;
  color: $light-grey; }

.auth__checkbox_wrap {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 0;
  justify-content: space-between; }

.fake__checkbox {
  display: inline-block;
  border: 1px solid $light-grey;
  width: 22px;
  height: 22px;
  border-radius: 3px;
  margin-right: 15px;
  position: relative; }

.auth__checkbox_wrap input:checked + .fake__checkbox {
  border-color: $yellow;
  &::after {
    content: '\f00c';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $awesome;
    color: $yellow;
    font-size: 14px; } }

.auth__info_link {
  color: $text-grey;
  text-decoration: underline;
  font-size: 10px;
  line-height: 13px;
  max-width: 195px; }

.confirm__form {
  align-items: flex-start;
  display: none; }

.previous__form_btn {
  padding: 0;
  color: $light-grey;
  margin-bottom: 10px;
  &::before {
    content: '\f060';
    font-family: $awesome;
    margin-right: 5px; } }

.confirm__code_input {
  color: $yellow;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 127px;
  align-self: flex-start; }

.confirm__timer_text {
  font-size: 12px;
  color: $light-grey;
  margin-top: 10px; }

.form__button__confirm.active {
  background: $yellow;
  color: #fff; }

.second__section {
  margin-top: 50px;
  margin-bottom: 10px; }

.news__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

.news__item {
  max-width: 600px; }

.news__image {
  max-width: 600px;
  overflow: hidden;
  border-radius: 5px;
  img {
    width: 100%;
    height: auto; } }

.news__link {
  color: $yellow;
  font-size: 14px; }

.news__title {
  font-size: 18px;
  color: black;
  margin: 5px 0; }

.news__desc {
  color: $text-grey;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0; } }

.section__title {
  font-size: 36px;
  margin-top: 50px; }

.curses__filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.curses__wrap {
  margin-right: 20px;
  &:last-child {
    margin-right: 0; } }

.curses__text {
  max-width: 255px;
  font-size: 18px;
  color: $form-grey;
  display: block;
  border: 1px solid $form-grey;
  padding: 16px 23px;
  text-align: center;
  transition: .3s;
  border-radius: 5px;
  margin-bottom: 33px;
  &:hover {
    border-color: $yellow; } }

.curses__input {
  display: none;
  &:checked + .curses__text {
    border-color: transparent;
    background: $yellow; } }


.quarter__text {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 3px;
  color: $text-grey;
  min-width: 38px;
  min-height: 26px;
  text-align: center;
  line-height: 26px;
  transition: .3s;
  &:hover {
    border-color: $yellow;
    color: $yellow; } }

.quarter__input {
  display: none;
  &:checked + .quarter__text {
    border-color: $yellow;
    color: $yellow; } }


.video__slider {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.video__item {
  margin-right: 10px;
  &:last-child {
    margin-right: 0; } }

.video__wrap {
  min-width: 240px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  img {
    width: 100%;
    height: auto; } }

.video__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: block;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: $yellow;
  text-align: center;
  line-height: 37px;
  color: #fff;
  .fas.fa-play {
    margin-left: 2px; }
  &::after, &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background: $yellow;
    z-index: -1; }
  &::after {
    width: 53px;
    height: 53px;
    opacity: .5; }
  &::before {
    width: 67px;
    height: 67px;
    opacity: .5; } }

.video__title {
  font-size: 18px;
  color: $form-grey;
  font-weight: normal;
  margin-top: 15px; }

.fourth__title {
  margin-bottom: 0; }


.fourth__subtitle p {
  margin-top: 10px;
  margin-bottom: 30px;
  color: $text-grey; }

.video__title {
  color: $form-grey; }

.fiveth__container {

  margin-top: 140px; }

.fiveth__section_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.fiveth__title {
  font-size: 30px;
  margin: 0;
  max-width: 380px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background: $yellow;
    height: 4px;
    width: 65%;
    border-radius: 5px;
    bottom: -20px;
    left: 0; } }

.advantages__list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap; }

.advantages__item {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.advantages__index {
  font-size: 105px;
  font-weight: bold;
  color: $text-grey;
  opacity: .05;
  margin-right: 20px;
  align-self: flex-start; }


.advantages__text {
  color: $form-grey; }

.sixth__section {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px; }

.sixth__banner {
  max-width: 788px;
  max-height: 340px;
  overflow: hidden;
  border-radius: 0 5px 5px 0;
  img {
    width: 100%;
    height: auto; } }

.sixth__title {
  margin-top: 30px;
  font-size: 30px; }

.sixth__item {
  vertical-align: middle;
  margin-bottom: 35px;
  &:first-child {
    margin-bottom: 32px; }
  &:last-child {
    margin-bottom: 0; } }

.sixth__link {
  display: block;
  margin: 15px 0;
  &:last-child {
    margin: 0; } }

.seventh__section {
  margin-top: 150px;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.seventh__left {
  text-align: center; }

.seventh__title {
  margin: 20px auto;
  max-width: 280px; }

.form__input_seventh {
  margin-bottom: 15px;
  border-color: $text-grey;
  &:last-child {
    margin: 0; } }

.form__textarea {
  resize: none;
  min-height: 180px;
  font-family: $monserat;
  padding: 0 15px; }

.seventh__btn {
  border-radius: 25px;
  outline: none;
  font-size: 18px;
  background: $yellow;
  padding: 10px 53px; }

.seventh__right {
  max-width: 1039px;
  overflow: hidden;
  border-radius: 6px 0px 0px 6px;
  img {
    width: 100%;
    height: auto; } }


.form__block_seventh {
  margin: 0 auto; }

