.video__player {
  max-width: 540px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: .3;
  background: black; }

#video {
  width: 100%;
  height: 100%; }

.play__video_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 35px;
  height: 35px;
  background: $yellow;
  border-radius: 50%;
  color: $white;
  padding: 0;
  z-index: 100;
  &:focus {
    box-shadow: none; }
  &:hover {
    color: $white; }
  &:before,&::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    z-index: -1; }
  &::before {
    width: 55px;
    height: 55px;
    background: rgba($yellow, .5); }
  &::after {
    width: 75px;
    height: 75px;
    background: rgba($yellow, .2); }
  &:active {
    &:before, &:after {
      animation: click .5s; } } }

@keyframes click {
  0% {
    width: 55px;
    height: 55px; }
  50% {
    width: 35px;
    height: 35px; }
  100% {
    width: 55px;
    height: 55px; } }

.player__video_controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: rgba(255,255,255, .2);
  z-index: 100;
  padding: 5px 10px; }

.progress__line_video {
  height: 3px;
  border-radius: 5px;
  background: $dark-white;
  cursor: pointer;
  position: relative;
  margin-left: 5px;
  margin-top: 6px; }

.progress__bar_video {
  height: 3px;
  background: $yellow;
  width: 0; }

.progress__point_video {
  width: 10px;
  height: 10px;
  background: $yellow;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 0;
  cursor: pointer; }

.controls__top {
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.full__screen_btn {
  color: $white;
  padding: 0;
  &:focus {
    box-shadow: none; }
  &:hover {
    color: $yellow; } }

.elapsed__text_time,.duration__text_time {
  font-weight: 300; }
