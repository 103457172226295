.footer {
  background: $grey;
  margin-top: 100px; }

.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

.footer__wrap {
  margin-top: 30px; }

.footer__wrap_logo {
  max-width: 558px; }

.footer__logo {
  max-width: 206px;
  overflow: hidden;
  img {
    width: 100%; } }

.footer__title {
  font-size: 18px;
  color: $yellow;
  font-weight: bold; }

.footer__desc {
  font-size: 14px;
  margin-top: 15px;
  font-weight: lighter;
  color: $white;
  line-height: 25px;
  p {
    margin: 0; } }

.contact__item .footer__title:first-child {
  margin-top: 0; }

.footer__title_logo {
  margin-top: 37px;
  margin-bottom: 15px; }

.footer__subtitle {
  color: $white;
  font-weight: normal; }

.contact__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.contact__item {
  &:last-child {
    margin-bottom: 0;
    margin-left: 100px; } }

.footer__item {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0; } }

.footer__link {
  font-size: 20px;
  transition: .3s;
  &:hover {
    color: $yellow; } }

.footer__title_white {
  color: $white;
  margin-top: 70px; }

.social__list {
  display: flex;
  align-items: center; }

.social__item {
  margin-right: 20px;
  &:last-child {
    margin-right: 0; } }

.social__link {
  display: block;
  width: 35px;
  height: 35px; }

$socials: facebook,instagram,telegram,tiktok,whatsapp;
@each $social in $socials {
  .social__link-#{$social} {
    background-image: url(../images/svg/sprite.svg##{$social}); } }

.footer__title {
  margin-bottom: 10px; }

.footer__bottom {
  background: $grey;
  margin-top: 100px;
  border-top: 1px solid #fff; }

.footer__bottom_container {
  text-align: center;
  color: $white;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400; }

.footer__bottom_text-yellow {
  color: $yellow; }
