@import '../fonts/montserrat/stylesheet.css';
@import '../fonts/fontawesome/css/all.min.css';
@import '_settings';
@import '_header';
@import '_footer';
@import '_first_page';
@import '_home';
@import '_course_page';
@import '_personal';
@import '_player';
@import '_video';
@import '_eventCard';
@import '_personal_data';
@import '_media';
