.form__block_event {
  position: relative;
  margin-bottom: 20px; }

.event__color {
  position: absolute;
  width: 5px;
  height: 100%;
  border-radius: 5px;
  background: $green;
  top: 0;
  left: 0; }

.event__title {
  color: $yellow;
  font-size: 18px;
  margin-bottom: 5px; }

.control {
  font-size: 18px;
  color: $text-grey;
  &:hover {
    color: $yellow; }
  &:first-child {
    margin-right: 15px; } }

.card__date {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px; }
