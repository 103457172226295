.home__title {
  font-size: 18px;
  margin-top: 30px; }

.home__class_form {
  background: #ffffff;
  padding: 10px;
  width: 100%;
  max-width: 328px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: 37px; }

.home__search_form {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 25px; }

.home__search_input {
  border: none;
  outline: none;
  height: 37px;
  padding: 0;
  width: 100%;
  padding: 10px; }

.home__search_btn {
  background: $yellow;
  color: $black;
  &:hover {
    color: #fff; } }

.home__input_wrap {
  margin: 0; }

.home__index {
  color: $text-grey;
  border-radius: 5px;
  transition: .3s;
  &:hover {
    color: $yellow; } }

.home__input {
  display: none;
  &:checked + .home__index {
    color: $yellow; } }

.slider__title {
  color: $light-grey;
  margin-top: 20px;
  padding: 0;
  font-size: 12px; }

.slider__list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px; }


.slider__item {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(251, 152, 7, 0.3);
  margin-bottom: 20px;
  overflow: hidden; }


.slider__wrap {
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto; } }

.slider__subtitle {
  margin-top: 15px;
  color: $form-grey;
  font-size: 12px;
  font-weight: normal;
  padding: 0 10px;
  min-height: 30px; }

.slider__button {
  width: 100%;
  border-top: 1px solid $white;
  border-radius: 0;
  color: $green;
  font-size: 12px;
  &::after {
    content: '\f061';
    font-family: $awesome;
    margin-left: 5px; } }

