.personal__form_block {
  padding: 10px;
  position: relative;
  margin-bottom: 10px; }

.personal__first .personal__form_block, {
  margin-top: 30px; }

.personal__image {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  overflow: hidden;
  border: 2px solid $yellow;
  margin-right: 15px; }

.personal__image_form {
  position: absolute;
  z-index: 2; }

.personal__image_input {
  display: none; }

.personal__image_btn {
  background: url(../images/svg/sprite.svg#add_photo) center center no-repeat;
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  right: -60px;
  top: 5px;
  cursor: pointer; }

.personal__data {
  font-size: 12px;
  color: $light-grey; }

.personal__page_name {
  color: $text-grey; }

.personal__tabs_section {
  margin-top: 20px; }

.personal__tabs_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px; }

.personal__tabs_link {
  display: inline-block;
  color: $text-grey;
  position: relative;
  transition: .3s;
  padding-bottom: 5px;
  &:hover {
    color: $yellow;
    text-decoration: none; }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    background: $yellow;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    left: 0;
    display: none; } }

.personal__tabs_item {
  margin-right: 20px;
  margin-bottom: 5px;
  &.active .personal__tabs_link::after {
    display: block; } }

.personal__content_list {
  margin-top: 15px; }

.personal__content_item {
  display: none;
  &.active {
    display: block; } }

.personal__grid_link {
  color: $light-grey;
  display: block;
  margin-bottom: 20px;
  &:hover {
    color: $yellow;
    text-decoration: none; }
  &::before {
    content: '\f00a';
    font-family: $awesome;
    font-weight: bold;
    margin-right: 5px; } }

.personal__blocks {
  &.active {
    display: flex;
    flex-wrap: wrap; } }

.personal__block_top {
  display: flex;
  align-items: flex-start;
  &.active__card {
    flex-wrap: wrap; } }

.personal__progress__block {
  width: 100%; }

.personal__block_image {
  max-width: 42%;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
  img {
    width: 100%; }
  &.active__card {
    margin-right: 0;
    margin-bottom: 10px;
    max-width: 100%; } }

.personal__progress_title {
  font-size: 12px;
  color: $black;
  margin-bottom: 14px; }

.progress__block {
  background: $white;
  height: 5px;
  border-radius: 5px;
  overflow: hidden; }

.progressbar {
  height: 5px;
  width: 20%;
  background: $red;
  border-radius: 5px; }

.personal__progress_button {
  color: $green;
  display: block;
  border: 1px solid $light-grey;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  transition: .3s;
  margin-top: 10px;
  &::after {
    content: '\f061';
    font-family: $awesome;
    margin-left: 5px; }
  &:hover {
    text-decoration: none;
    background: $green;
    color: white; } }

.personal__info {
  display: none;
  margin-top: 10px;
  color: $light-grey;
  font-size: 14px;
  font-weight: 300; }

.persona__info__title {
  font-size: 12px;
  font-weight: 600;
  color: $black; }






