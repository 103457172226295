.personal__data_form {
  margin-top: 30px; }

.personal_data__form_block {
  display: flex;
  flex-direction: column;
  aling-items: center; }

.personal__title {
  color: $yellow;
  font-size: 18px;
  margin-bottom: 20px; }

.personal_data__input {
  padding: 18px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 1px solid $light-grey;
  color: $light-grey;
  outline: none;
  padding-left: 10px; }

.persona_data__btn {
  border: 1px solid $yellow;
  margin: 0 auto;
  padding: 20px 75px;
  font-size: 18px;
  transition: .3s;
  margin-bottom: 20px;
  margin-top: 5px;
  &:hover {
    background: $yellow;
    color: $white; } }
