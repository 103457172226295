@media screen and (min-width: 1200px) and (max-width: 1375px) {
  .slider__list {
    justify-content: space-around; } }

@media screen and (max-width: 1200px) {
  .sixth__section .container-fluid {
    flex: 0 0 100%;
    max-width: 100%; }

  .slider__list {
    justify-content: space-around; } }

@media screen and (max-width: 1182px) {
  .form__block_first {
    margin: 45px auto; }

  .fiveth__section_wrap {
    flex-wrap: wrap; } }

@media screen and ( max-width: 1024px ) {
  .first__section {
    background: linear-gradient(180deg, #fb9807 0%,#ffcc4a 100%); }

  .header__nav {
    display: none; }

  .form__block_first {

    padding: 25px; }

  .first__section_banner {
    display: none; }

  .form__block_first {
    max-width: 300px; }

  .advantages__item {
    align-items: center; }

  .seventh__section {
    margin-top: 50px; }

  .slider__list {
    justify-content: space-between; }

  .slider__item {
    max-width: 156px; }

  .footer__wrap_contacts {
    order: 3; }

  .footer__wrap_socials {
    order: 2; } }

@media screen and (max-width: 768px) {
  .footer__wrap_contacts {
    order: 2; }

  .footer__wrap_socials {
    order: 3; }

  .form__block_course_desktop {
    display: none; }

  .form__block_course_mobile {
    display: block; }

  .course__image {
    margin: 0; }

  .course__acco_title {
    font-size: 14px;
    font-weight: 600; }

  .course__subtitle {
    font-size: 13px; }

  .course__text {
    font-size: 12px; } }


@media screen and (min-width: 768px) {
  .home__search_form {
    max-width: 934px;
    display: flex; }

  .personal__content_list .personal__form_block {
    display: flex;
    max-width: 100%; }

  .personal__blocks.active .personal__form_block {
    margin-right: 10px; }

  .personal__block_top {
    flex-wrap: wrap;
    max-width: 260px;
    margin-right: 20px;
    &.active__card {
      margin-right: 0; } }

  .personal__block_image {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px; }

  .audio__player {
    min-height: 40px; }
  .play__btn {
    width: 30px;
    height: 30px;
    &::before {
      width: 40px;
      height: 40px; } }

  .progress__line,.progress__line_video {
    height: 6px; }

  .progress__bar,.progress__bar_video {
    height: 6px; }

  .progress__point,.progress__point_video {
    width: 15px;
    height: 15px; }

  .personal_data__form_block {
    margin: 0 auto; }

  .personal__title {
    font-size: 24px; }

  .personal__page_name {
    font-size: 18px; }

  .persona__user_name {
    font-size: 14px; } }

@media screen and (max-width: 533px) {
  .contact__item {
    margin-bottom: 20px;
    &:last-child {
      margin-left: 0; } } }

@media screen and (max-width: 320px) {
  .slider__item {
    max-width: 140px; }

  .fourth__title {
    font-size: 30px; } }
