.wrapper__inner {
  background: #EEF5F9; }

.course__main {
  margin: 10px;
  background: #ffffff;
  padding-top: 15px; }

.course__title {
  font-size: 18px;
  color: $black;
  font-weight: 600; }

.course__subtitle {
  font-weight: 600;
  font-size: 18px; }

.course__text {
  font-weight: 400;
  font-size: 18px; }

.course__preview {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; }

.course__image {
  max-width: 328px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(black, .4);
  flex-basis: 328px;
  margin-bottom: 20px;
  margin-right: 30px;
  img {
    width: 100%; } }

.course__description {
  margin-top: 20px; }

.course__desc_btn {
  &::after {
    content: '\f106';
    font-family: $awesome;
    margin-left: 20px;
    transform: rotate(180deg); } }

.form__block_course {
  padding: 10px;
  margin: 20px 0;
  font-size: 14px;
  &_desktop {
    margin-top: 0;
    max-width: 50%; }
  &_mobile {
    display: none; } }

.form__block_image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  background: grey;
  margin-right: 20px;
  img {
    width: 100%;
    height: auto; } }

.form__block_text {
  p {
    margin: auto; } }

.form__block_list {
  list-style: none;
  margin: 0;
  padding: 0; }

.form__block_title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: $black; }

.course__quarter_link {
  display: block;
  color: $text-grey;
  font-size: 18px;
  padding: 7px 15px;
  &:hover {
    color: $yellow;
    text-decoration: none; }
  &.active {
    color: $yellow; } }

.course__quarter_item {
  margin-right: 5px;
  &:last-child {
    margin: 0; }
  &.active {
    .course__quarter_link {
      color: $yellow;
      box-shadow: $form-shadow; } } }

.course__quarter_content {
  margin-top: 20px;
  padding-bottom: 100px; }

.course__content {
  margin-top: 30px; }

.course__content_item {
  display: none;
  max-width: 100%;
  &.active {
    display: block;
    padding-bottom: 70px; } }

.advantage__link {
  color: $text-grey;
  font-size: 14px;
  border-bottom: 1px solid $text-grey;
  transition: .3s;
  &:hover {
    text-decoration: none;
    color: $yellow;
    border-color: $yellow; } }

.comments__title {
  font-size: 16px;
  color: $black;
  font-weight: 600; }

.comment__form_user {
  display: flex;
  align-items: center;
  margin-top: 20px; }

.comment__form_image_wrap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  img {
    width: 100%;
    height: auto; } }

.comment__user_name {
  color: $light-grey;
  font-size: 10px;
  margin: 0; }

.comment__input {
  width: 100%;
  border: none;
  border-bottom: 1px solid $light-grey;
  color: $text-grey;
  font-size: 12px;
  transition: .3s;
  margin-top: 10px;
  &:focus {
    outline: none;
    border-color: $yellow; } }

.comment__btn {
  padding: 0;
  margin-right: 20px;
  margin-top: 10px;
  background: $white;
  padding: 10px 16px;
  &:last-child {
    margin-right: 0;
    background: none; } }

.comment__user_time {
  color: $text-grey;
  font-size: 10px;
  margin-left: 10px; }

.course__comment_done {
  margin-bottom: 40px; }

.comment__text {
  margin-top: 10px;
  font-size: 14px;
  color: $black;
  p {
    margin: 0; } }

.course__comment_link {
  color: $yellow;
  font-size: 14px; }

.course__comment_show {
  color: $blue;
  font-size: 14px;
  &::before {
    content: '\f0d7';
    font-family: $awesome;
    font-weight: bold;
    margin-right: 5px; } }

.course__additional_title {
  font-size: 18px;
  margin-bottom: 15px; }

.additional__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.additional__item {
  margin-right: 20px;
  margin-bottom: 15px; }

.additional__link {
  color: $black;
  font-size: 14px;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
  &:hover {
    text-decoration: none;
    color: $yellow; } }


.additional__item.active {
  .additional__link {
    font-weight: 600;
    border-color: $yellow; } }

.additional__content_item {
  display: none;
  &.active {
    display: block; } }



.course__theme-list {
  margin-top: 15px; }

.form__block_item {
  margin-top: 10px;
  font-size: 12px;
  &:first-child {
    margin-top: 0; } }

.theme__item {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0; } }


.course__acco_title {
  margin: 0;
  padding: 10px 20px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  transition: .3s;
  &::after {
    content: '\f106';
    font-family: $awesome;
    position: absolute;
    left: 0px;
    top: 8px;
    color: $yellow;
    transition: .3s; }
  &:hover {
    color: $yellow; }
  &.collapsed {
    &::after {
      content: '\f107'; } } }
.course__acco_content {
  padding-left: 20px; }

.test__form_block {
  display: flex;
  flex-direction: column;
  color: $light-grey;
  input {
    margin-right: 10px; } }

.test__title {
  font-size: 14px;
  color: $black; }

.test__button {
  align-self: flex-start;
  background: $yellow; }
