// colors
$black: #333333;
$grey: #262C34;
$form-grey: #2B333F;
$text-grey: #828282;
$light-grey: #95999f;
$yellow: #FB9807;
$yellow-dark: #C87700;
$background: #fffcf8;
$bg-grey: #3A3E45;
$white: #f0f0f0;
$dark-white: #dddddd;
$green: #27AE60;
$blue: #4FA7EA;
$red: #D85050;

$form-shadow: 0px 0px 10px rgba(251, 152, 7, 0.3);

//fonts
$monserat: 'Montserrat', sans-serif;
$awesome: 'Font Awesome 5 Pro', 'Font Awesome 5 Brands';

html,body {
  font-family: $monserat;
  position: relative;
  width: 100%;
  height: 100%; }

.wrapper {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  background: $background; }

.list {
  list-style: none;
  margin: 0;
  padding: 0; }

.link {
  text-decoration: none; }

.btn {
  border: none;
  transition: .3s;
  outline: none; }

.form__block {
  max-width: 540px;
  border-radius: 5px;
  background: #fff;
  box-sizing: border-box;
  padding: 20px 24px;
  box-shadow: $form-shadow; }


.form__title {
  color: $black;
  font-size: 20px; }

.btn__link {
  display: block;
  width: 100%;
  padding: 16px 0;
  text-align: center;
  border-radius: 5px;
  border: 1px solid $yellow;
  color: $black;
  transition: .3s;
  font-size: 14px;
  &:hover {
    text-decoration: none;
    color: #ffffff;
    background: $yellow; } }
