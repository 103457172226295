.audio__player {
  display: flex;
  align-items: center;
  min-height: 30px; }

.play__btn {
  width: 20px;
  height: 20px;
  background: $yellow;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
  line-height: 20px;
  padding-left: 2px;
  color: $white;
  position: relative;
  outline: none;
  margin-right: 20px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 30px;
    height: 30px;
    background: rgba($yellow, .3);
    border-radius: 50%;
    transform: translate(-50%,-50%);
    transition: .3s; }
  &:hover {
    color: $white; }
  &:focus {
    box-shadow: none; }
  &:active {
    &:before {
      animation: clickBtn .5s; } } }

@keyframes clickBtn {
  0% {
    width: 30px;
    height: 30px; }
  50% {
    width: 25px;
    height: 25px; }
  100% {
    width: 30px;
    height: 30px; } }

.player__controls {
  align-self: flex-end; }

.progress__line {
  height: 3px;
  border-radius: 5px;
  background: $dark-white;
  cursor: pointer;
  margin-bottom: 2px;
  position: relative; }

.progress__bar {
  height: 3px;
  background: $yellow;
  width: 0; }

.progress__point {
  width: 10px;
  height: 10px;
  background: $yellow;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 0;
  cursor: pointer; }

.time__block {
  min-width: 214px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: $dark-white; }
