.header {
  background: $grey; }

.container__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.header__left {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.header__logo {
  padding: 20px 0; }

.header__nav {
  margin-left: 60px; }

.header__nav_list {
  display: flex;
  align-items: center; }

.header__nav_item {
  margin-right: 100px;
  &:last-child {
    margin-right: 0; } }

.header__nav_link {
  color: $text-grey; }

.header__btn_language {
  border: none;
  outline: none;
  background: none;
  color: $light-grey;
  font-size: 14px; }

